<script setup lang="ts">
import { usePinStore } from '@stores/usePinStore';
import { useAuthStore } from '@stores/useAuthStore';
import { AppInput, useToast } from '@mogelijk-technologies/ui-library';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useMutation } from '@urql/vue';
import { VERIFY_PINCODE, UPDATE_PINCODE, RESET_PINCODE } from '@graphql/mutations/pincode';
import { BiometricSensor } from '@graphql/enums/mobile';
import { ResetPincodeStatus, UpdatePincodeStatus, VerifyPincodeStatus } from '@graphql/enums/pincode';

import type { VerifyPincodeResponse, UpdatePincodeResponse, ResetPincodeResponse } from '@graphql/types/pincode';

const { user } = toRefs(useAuthStore());
const {
  firstCode,
  secondCode,
  biometricSensor,
  isPincodeVisible,
  isUsingBiometrics,
  isResettingPincode,
  isDisablingPincode,
} = toRefs(usePinStore());
const {
  setFirstCode,
  setSecondCode,
  showPincodeOverlay,
  hidePincodeOverlay,
  setIsResettingPincode,
  setIsUsingBiometrics,
  setIsDisablingPincode,
  requestBiometricAuthentication,
} = usePinStore();

const { setHasPincode } = useAuthStore();

const { executeMutation: verifyPincode } = useMutation<VerifyPincodeResponse>(VERIFY_PINCODE);
const { executeMutation: updatePincode } = useMutation<UpdatePincodeResponse>(UPDATE_PINCODE);
const { executeMutation: resetPincode } = useMutation<ResetPincodeResponse>(RESET_PINCODE);

const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

const oldCode = ref('');
const currentCode = ref('');
const password = ref('');
const isRetry = ref(false);
const isPincodeForgotten = ref(false);
const isIncorrectPassword = ref(false);
const isFetching = ref(false);

const isFirstCode = computed(() => (!user.value?.has_pincode && firstCode.value.length < 4));
const isSecondCode = computed(() => firstCode.value && secondCode.value.length < 4);

const pincodeTitle = computed(() => {
  if (isRetry.value) return 'Pincode klopt niet, probeer opnieuw';

  if (isFirstCode.value && !isDisablingPincode.value) return 'Stel uw pincode in';

  if (isResettingPincode.value) {
    if (firstCode.value) return 'Herhaal uw nieuwe pincode';

    if (oldCode.value) return 'Stel uw nieuwe pincode in';

    return 'Voer uw oude pincode in';
  }

  if (isSecondCode.value) return 'Herhaal uw pincode';

  return 'Voer uw pincode in';
});

const checkPincode = async (pincode: string) => {
  isFetching.value = true;

  const { data } = await verifyPincode({ input: { pincode } });

  isFetching.value = false;

  return data?.verifyPincode.status === VerifyPincodeStatus.SUCCESS;
};

const resetState = () => {
  oldCode.value = '';
  currentCode.value = '';
  setFirstCode('');
  setSecondCode('');
};

const onKeyPressed = async (key: number) => {
  isRetry.value = false;
  currentCode.value += key;

  if (currentCode.value.length !== 4) return;

  if (isDisablingPincode.value) {
    if (await checkPincode(currentCode.value)) {
      isFetching.value = true;
      await updatePincode({ input: { old_pincode: currentCode.value, pincode: null } });
      isFetching.value = false;
      setIsDisablingPincode(false);
      setIsUsingBiometrics(false);
      setHasPincode(false);
      resetState();
      hidePincodeOverlay();
    } else {
      isRetry.value = true;
    }
  } else if (isResettingPincode.value) {
    if (firstCode.value) {
      if (firstCode.value === currentCode.value) {
        isFetching.value = true;

        const { data } = await updatePincode({ input: { old_pincode: oldCode.value, pincode: currentCode.value } });

        isFetching.value = false;

        if (data?.updatePincode.status === UpdatePincodeStatus.SUCCESS) {
          setIsResettingPincode(false);
          resetState();
          hidePincodeOverlay();
        } else {
          isRetry.value = true;
        }
      } else {
        setSecondCode('');
        isRetry.value = true;
      }
    } else if (oldCode.value) {
      setFirstCode(currentCode.value);
    } else if (await checkPincode(currentCode.value)) {
      // setFirstCode(currentCode.value);
      oldCode.value = currentCode.value;
    } else {
      isRetry.value = true;
    }
  } else if (user.value?.has_pincode && await checkPincode(currentCode.value)) {
    hidePincodeOverlay();
  } else if (isFirstCode.value) {
    setFirstCode(currentCode.value);
  } else if (isSecondCode.value) {
    setSecondCode(currentCode.value);

    if (firstCode.value === secondCode.value) {
      isFetching.value = true;

      const { data } = await updatePincode({ input: { old_pincode: oldCode.value, pincode: secondCode.value } });

      isFetching.value = false;

      if (data?.updatePincode.status === UpdatePincodeStatus.SUCCESS) {
        hidePincodeOverlay();
        setHasPincode(true);
        resetState();
      } else {
        isRetry.value = true;
      }
    } else {
      isRetry.value = true;
      resetState();
    }
  } else {
    isRetry.value = true;
  }

  currentCode.value = '';
};

const onDeleteLeft = () => {
  currentCode.value = currentCode.value.slice(0, -1);
};

const onClear = () => {
  currentCode.value = '';
};

const onForgotPincode = () => {
  isPincodeForgotten.value = true;
};

const pincodeForgotten = async () => {
  const { data } = await resetPincode({ input: { pincode: null, password: password.value } });

  if (data?.resetPincode.status === ResetPincodeStatus.SUCCESS) {
    setFirstCode('');
    setSecondCode('');
    setIsUsingBiometrics(false);
    setIsResettingPincode(false);
    setHasPincode(false);
    hidePincodeOverlay();
    resetState();
    isPincodeForgotten.value = false;
    isIncorrectPassword.value = false;
    isRetry.value = false;
  } else {
    isIncorrectPassword.value = true;

    const toast = await useToast({
      title: 'Fout',
      message: 'Het wachtwoord is onjuist, probeer het opnieuw.',
      theme: 'error',
    });

    toast.present();
  }
};

const handleVisibilityChange = () => {
  if (!document.hidden) {
    showPincodeOverlay();
  } else {
    resetState();
    hidePincodeOverlay();
  }
};

onMounted(() => {
  document.addEventListener('visibilitychange', handleVisibilityChange);
});

onUnmounted(() => {
  document.addEventListener('visibilitychange', handleVisibilityChange);
});
</script>

<template>
  <div
    v-if="isPincodeVisible"
    class="z-[99] fixed top-0 left-0 w-screen h-screen backdrop-blur-lg flex flex-col items-center justify-center bg-zinc-700/30 animate-slideDown"
  >
    <Transition
      leave-from-class="mt-0 opacity-100"
      leave-to-class="-mt-40 opacity-0"
      leave-active-class="transition-all ease-in duration-500"
      move-class="transition-all ease-in duration-500"
    >
      <section v-if="!isPincodeForgotten" class="transition-all flex flex-col items-center gap-4 text-white" :class="{'animate-shake': isRetry}">
        <FontAwesomeIcon v-if="isFetching" :icon="['fas', 'spinner']" class="animate-spin text-xl h-6" />
        <p v-else class="drop-shadow-md leading-6">{{ pincodeTitle }}</p>
        <div class="flex justify-between gap-4 drop-shadow-md">
          <div class="rounded-full border border-white size-3 transition-all" :class="{'bg-white': currentCode.length > 0}" />
          <div class="rounded-full border border-white size-3 transition-all" :class="{'bg-white': currentCode.length > 1, '-mt-1': currentCode.length === 1}" />
          <div class="rounded-full border border-white size-3 transition-all" :class="{'bg-white': currentCode.length > 2, '-mt-1': currentCode.length === 2}" />
          <div class="rounded-full border border-white size-3 transition-all" :class="{'bg-white': currentCode.length > 3, '-mt-1': currentCode.length === 3}" />
        </div>
        <div class="grid grid-cols-3 gap-3 pt-4">
          <button
            v-for="(key, index) in keys"
            :key="key"
            class="transition-colors size-20 !p-0 shadow-inner rounded-full grid place-items-center text-3xl text-white bg-zinc-700/20 active:bg-white/20"
            :class="{'col-start-2': index === keys.length - 1}"
            @click="onKeyPressed(key)"
          >
            {{ key }}
          </button>
          <button
            v-if="!isUsingBiometrics || isResettingPincode || isDisablingPincode"
            class="transition-colors size-20 rounded-full grid place-items-center text-4xl !p-0
            bg-transparent drop-shadow-md text-white col-start-1 row-start-4 active:!bg-white/20"
            @click="onClear"
          >
            <FontAwesomeIcon :icon="['fal', 'xmark']" />
          </button>
          <button
            v-else-if="biometricSensor === BiometricSensor.FINGERPRINT && !isResettingPincode && !isDisablingPincode && user?.has_pincode"
            class="transition-colors size-20 rounded-full grid place-items-center text-4xl !p-0
            bg-transparent drop-shadow-md text-white col-start-1 row-start-4 active:!bg-white/20"
            @click="requestBiometricAuthentication"
          >
            <FontAwesomeIcon :icon="['fal', 'fingerprint']" />
          </button>
          <button
            v-else-if="biometricSensor === BiometricSensor.FACE_ID && !isResettingPincode && !isDisablingPincode && user?.has_pincode"
            class="transition-colors size-20 rounded-full grid place-items-center text-4xl !p-0
            bg-transparent drop-shadow-md text-white col-start-1 row-start-4 active:!bg-white/20"
            @click="requestBiometricAuthentication"
          >
            <FontAwesomeIcon :icon="['fal', 'face-viewfinder']" />
          </button>
          <button
            class="transition-colors size-20 rounded-full grid place-items-center text-4xl !p-0
            bg-transparent drop-shadow-md text-white col-start-3 row-start-4 active:!bg-white/20"
            @click="onDeleteLeft"
          >
            <FontAwesomeIcon :icon="['fal', 'delete-left']" />
          </button>
        </div>
      </section>
    </Transition>
    <div class="mt-6 min-h-7">
      <section v-if="isRetry" class="flex flex-col gap-4 text-white drop-shadow-md">
        <button
          class="animate-slideDown bg-transparent text-white"
          @click="onForgotPincode"
        >
          Pincode vergeten?
        </button>
        <template v-if="isPincodeForgotten">
          <p class="text-sm">Voer uw wachtwoord in om uw pincode te resetten.</p>
          <AppInput
            name="password"
            type="password"
            class="transparent-input"
            :class="{'transparent-input-error': isIncorrectPassword }"
            ignore-validation
            :model-value="password"
            @update:model-value="(value: string) => password = value"
          />
          <FontAwesomeIcon v-if="isFetching" :icon="['fas', 'spinner']" class="animate-spin text-2xl" />
          <button v-else class="bg-transparent text-white" @click="pincodeForgotten">
            Pincode resetten
          </button>
          <button class="bg-transparent text-white" @click="isPincodeForgotten = false">
            Annuleren
          </button>
        </template>
      </section>
    </div>
  </div>
</template>

<style lang="css">
.transparent-input div input {
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white;
}

.transparent-input-error div input {
  border-color: var(--color-red-700) !important;
}
</style>
