import { gql } from '@urql/core';

export const VERIFY_PINCODE = gql`
  mutation VerifyPincode ($input: VerifyPincodeInput!) {
    verifyPincode(input: $input) {
      status
      message
      __typename
    }
  }
`;

export const UPDATE_PINCODE = gql`
  mutation UpdatePincode ($input: UpdatePincodeInput!) {
    updatePincode(input: $input) {
      status
      message
      __typename
    }
  }
`;

export const RESET_PINCODE = gql`
  mutation ResetPincode ($input: ResetPincodeInput!) {
    resetPincode(input: $input) {
      status
      message
      __typename
    }
  }
`;
