export enum VerifyPincodeStatus {
  SUCCESS = 'SUCCESS',
  PINCODE_INVALID = 'PINCODE_INVALID',
}

export enum UpdatePincodeStatus {
  SUCCESS = 'SUCCESS',
  PINCODE_INVALID = 'PINCODE_INVALID',
  PINCODE_VALIDATION = 'PINCODE_VALIDATION',
}

export enum ResetPincodeStatus {
  SUCCESS = 'SUCCESS',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
}
