<script setup lang="ts">
/* eslint-disable unicorn/numeric-separators-style */
import BrowserNotification from '@components/BrowserNotification.vue';
import PincodeOverlay from '@components/PincodeOverlay.vue';
import { usePinStore } from '@stores/usePinStore';

import { useRoute } from 'vue-router';
import { useHead } from 'nuxt/app';
import { AppToasts, AppModal, AppTooltips, Footer, debounce, MogelijkLoader } from '@mogelijk-technologies/ui-library';
import { useAuthStore } from '@stores/useAuthStore';
import { isIosOrAndroid } from '@helpers/mobile';

const route = useRoute();

const { isLoading } = toRefs(useAuthStore());
const { queryInitialUser } = useAuthStore();

const { secondsTillLock } = toRefs(usePinStore());

const { showPincodeOverlay } = usePinStore();

const isPhone = ref(isIosOrAndroid());

const setPincodeTimeout = () => {
  debounce(showPincodeOverlay, 395517, secondsTillLock.value * 1000);
};

onMounted(async () => {
  await queryInitialUser();

  if (isPhone.value === true) {
    window.addEventListener('touchstart', setPincodeTimeout);
  }
});

onUnmounted(() => {
  if (isPhone.value === true) {
    window.removeEventListener('touchstart', setPincodeTimeout);
  }
});

useHead(
  computed(() => ({
    meta: [
      {
        name: 'og:title',
        content: `${route.meta.title} | Mogelijk Vastgoedfinancieringen`,
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
      },
    ],
    titleTemplate: '%s | Mogelijk Vastgoedfinancieringen',
  })),
);
</script>

<template>
  <Head>
    <Title>{{ route.meta.title }}</Title>
    <Link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicons/favicon-32x32.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicons/favicon-16x16.png"
    />
  </Head>
  <div id="mogelijk__ui-library">
    <div v-if="isLoading" class="w-full h-screen grid place-items-center">
      <MogelijkLoader class="!w-40 h-auto pb-40" />
    </div>
    <div v-else class="flex h-full flex-col">
      <BrowserNotification />
      <slot />
      <AppToasts />
      <AppModal />
      <AppTooltips />
      <PincodeOverlay v-if="isPhone" />
      <div id="tooltip" />
      <div id="fullscreen" />
      <div class="flex items-end pt-10 sm:pt-0">
        <Footer class="justify-self-end" />
      </div>
    </div>
  </div>
</template>
